/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { navigate } from 'gatsby-link';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

import encodeForm from '../utils/encode-form';
import createGet from '../utils/create-get';
import useCurrentLanguage from '../hooks/use-current-language';

import headerContactSvg from '../img/headers/header-contact.svg';

import Layout from '../components/Layout';
import Container from '../components/Container2';
import MarkdownContent from '../components/MarkdownContent';
import { Button } from '../components/Buttons';
import AspectRatioImg from '../components/AspectRatioImg';

const Field = ({ name, label, required, disabled, children, ...props }) => (
  <div
    {...props}
    sx={{
      mb: '20px',
      opacity: disabled ? '0.5' : '1',
    }}
  >
    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
    <label
      htmlFor={name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
      }}
    >
      <div sx={{ mb: '8px' }}>
        {label}
        {required && <span sx={{ color: 'accent', ml: '4px' }}>*</span>}
      </div>
      {cloneElement(children, {
        required,
        id: name,
        name,
      })}
    </label>
  </div>
);

const baseInputStyles = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'text',
  bg: 'transparent',
  p: '12px',
  width: '100%',
  color: 'text',
  fontSize: '14px',
  '&:focus': {
    outline: 'solid 1px',
    outlineColor: 'text',
  },
};

const Input = (props) => <input {...props} sx={baseInputStyles} />;

const selectStyles = {
  ...baseInputStyles,
};

export const ContactPageTemplate = ({
  lang,
  get,
  confirmed,
  setConfirmed,
  formValues,
  onChange,
  onSubmit,
}) => (
  <section>
    <Container>
      <AspectRatioImg src={headerContactSvg} alt="" ratio={912 / 256} />
      <Styled.h1 sx={{ mt: 13 }}>{get('title')}</Styled.h1>
      <MarkdownContent sx={{ mt: 7 }}>{get('text')}</MarkdownContent>
      <form
        name="Demo Request"
        method="post"
        action={lang === 'fr' ? '/fr/contact/merci' : '/contact/thanks'}
        data-netlify="true"
        data-netlify-honeypot="favColor"
        onSubmit={onSubmit}
        sx={{ mt: 11 }}
      >
        <div
          sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', null, '0.5fr'],
            gridGap: '16px',
          }}
        >
          <div>
            <input type="hidden" name="form-name" value="contact" />

            <div hidden>
              <label htmlFor="favColor">
                Don't fill this out if you're human
                <input name="favColor" />
              </label>
            </div>

            <Field
              name="firstName"
              label={get('form.labels.firstName')}
              required
            >
              <Input
                type="text"
                value={formValues.firstName}
                onChange={onChange}
              />
            </Field>

            <Field name="lastName" label={get('form.labels.lastName')} required>
              <Input
                type="text"
                value={formValues.lastName}
                onChange={onChange}
              />
            </Field>

            <Field name="jobTitle" label={get('form.labels.jobTitle')}>
              <Input
                type="text"
                value={formValues.jobTitle}
                onChange={onChange}
              />
            </Field>

            <Field name="company" label={get('form.labels.company')}>
              <Input
                type="text"
                value={formValues.company}
                onChange={onChange}
              />
            </Field>

            <Field name="email" label={get('form.labels.email')} required>
              <Input
                type="email"
                value={formValues.email}
                onChange={onChange}
              />
            </Field>

            <Field
              name="telephone"
              label={get('form.labels.telephone')}
              required
              sx={{
                maxWidth: [null, null, '320px'],
              }}
            >
              <Input
                type="tel"
                value={formValues.telephone}
                onChange={onChange}
              />
            </Field>

            <Field
              name="country"
              label={get('form.labels.country')}
              sx={{
                maxWidth: [null, null, '320px'],
              }}
            >
              <CountryDropdown
                defaultOptionLabel=""
                value={formValues.country}
                onChange={(value, e) => onChange(e)}
                sx={selectStyles}
              />
            </Field>

            <Field
              name="region"
              label={get('form.labels.region')}
              disabled={!formValues.country}
              sx={{
                maxWidth: [null, null, '320px'],
              }}
            >
              <RegionDropdown
                blankOptionLabel=""
                defaultOptionLabel=""
                country={formValues.country}
                value={formValues.region}
                onChange={(value, e) => onChange(e)}
                disabled={!formValues.country}
                sx={selectStyles}
              />
            </Field>
          </div>
        </div>

        <label
          htmlFor="confirm"
          sx={{
            display: 'grid',
            gridTemplateColumns: '30px auto',
            my: '32px',
          }}
        >
          <input
            type="checkbox"
            id="confirm"
            name="confirm"
            checked={confirmed}
            onChange={(e) => setConfirmed(e.target.checked)}
            sx={{
              width: '18px',
              height: '18px',
              mt: '5px',
            }}
          />
          <div>{get('form.labels.confirm')}</div>
        </label>
        <Button
          type="submit"
          variant="secondary"
          disabled={!confirmed}
          sx={{
            mt: '48px',
            width: '100%',
            maxWidth: [null, null, '320px'],
          }}
          outlined
        >
          {get('form.labels.submit')}
        </Button>
      </form>
    </Container>
  </section>
);

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  const lang = useCurrentLanguage();

  const [confirmed, setConfirmed] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    company: '',
    email: '',
    telephone: '',
    country: '',
    region: '',
  });

  const updateFormValue = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const input = e.target;
    updateFormValue(input.name, input.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encodeForm({
        'form-name': form.getAttribute('name'),
        ...formValues,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  return (
    <Layout
      page="contact"
      seo={{
        title: frontmatter.pageTitle,
        description: frontmatter.description,
      }}
    >
      <ContactPageTemplate
        lang={lang}
        get={createGet(frontmatter)}
        confirmed={confirmed}
        setConfirmed={setConfirmed}
        formValues={formValues}
        onChange={handleChange}
        onSubmit={handleSubmit}
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        description
        title
        text
        form {
          labels {
            firstName
            lastName
            jobTitle
            company
            email
            telephone
            country
            region
            confirm
            submit
          }
        }
      }
    }
  }
`;
